"use strict";

document.addEventListener("DOMContentLoaded", function () {

  //Homepage Banner
  new Glider(document.querySelector('.tiny-slider-banner'), {
    slidesToShow: 1,
    slidesToScroll: 1,
    scrollLock: true,
    dots: '.banner-pager',
    draggable: false,
    rewind: true,
    arrows: {
      prev: '.prev-next-pager .prev',
      next: '.prev-next-pager .next'
    }
  });

  var newsPager = document.getElementsByClassName('view-all-news');

  if (newsPager[0] !== undefined) {

    newsPager[0].insertAdjacentHTML('beforebegin',
      '<div class="news-pager"></div><div class="news-prev-next-pager"><span class="prev" tabindex="0"></span><span class="next" tabindex="0"></span></div>');

    new Glider(document.querySelector('.news-items'), {
      slidesToShow: 1,
      slidesToShow: 1.25,
      slidesToScroll: 1,
      scrollLock: true,
      dots: '.news-pager',
      draggable: true,
      rewind: true,
      arrows: {
        prev: '.news-prev-next-pager .prev',
        next: '.news-prev-next-pager .next'
      }
    });
  }
});